@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.square {
		aspect-ratio: 1 / 1;
	}
	.btnShadow {
		box-shadow: 0 2px 10px #919191 !important;
	}
	.text-truncate {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.bg-graphics {
		background-image: url(https://content-strapi-lms.s3.ap-south-1.amazonaws.com/icons/lines_f077faf53d.svg);
		background-position: 50%;
		background-size: auto;
		background-repeat: no-repeat;
	}
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color: transparent;
}

/* width */
*::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 35px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: #555;
}

a {
	text-decoration: none;
}

p {
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 15px;
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5 {
	margin-top: 0px;
	font-style: normal;
	font-weight: 400;
}

ul,
ol,
li {
	margin: 0;
	padding: 0;
	list-style: none;
}

textarea {
	resize: none;
}

#root {
	min-height: 100vh;
}

.meeting > div {
	width: 100%;
	height: 80vh;
}

.react-reveal {
	width: 100%;
}

video {
	height: 100%;
}

.svgSize {
	font-size: 1.5rem !important;
}

.vjs-icon-cog {
	height: 100% !important;
	font-size: 16px !important;
}

.vjs-control-bar {
	border-bottom-left-radius: 24px;
	border-bottom-right-radius: 24px;
}

.MuiSlider-rail {
	@apply !bg-gradient-to-r !from-gray-100 !to-green-500;
	opacity: 1 !important;
}

.MuiSlider-track {
	background: transparent !important;
}

span.Description.Secondary {
	display: none;
}

.plyr {
	--plyr-color-main: #ff6600;
	--plyr-range-thumb-background: #ff6600;
	--plyr-menu-background: #181818;
	--plyr-menu-color: white;
	--plyr-menu-border-color: #262626;

	width: 100%;
	height: 100%;
}

.plyr__video-embed iframe {
	pointer-events: all !important;
}
.plyr__poster {
	z-index: -1 !important;
}

.plyr__progress input {
	cursor: pointer;
}

.plyr__video-wrapper {
	@apply md:rounded-md;
}

.plyr__video-embed__container {
	position: relative !important;
	top: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
	left: 0 !important;
	height: 100% !important;
	padding-bottom: 0 !important;
	transform: none !important;
}

iframe .player .vp-controls-wrapper {
	display: none;
}

.pulse {
	animation: pulse-animation 2s;
}

@keyframes pulse-animation {
	0% {
		@apply bg-gray-200;
	}
	100% {
		background-color: white;
	}
}

.break-words {
	word-break: break-word;
}

.overflow-anywhere {
	overflow-wrap: anywhere !important;
}

.mirror {
	transform: rotateY(180deg);
}

.sideBarControl {
	background: #000 !important;
	color: #fff !important;
}

.plyr__video-wrapper {
	@apply !rounded-none;
}

.MuiTab-textColorPrimary {
	@apply !bg-white !text-black;
}

.MuiTab-textColorPrimary.Mui-selected {
	@apply !bg-black !text-white !rounded-md;
}

.MuiTabs-indicator {
	@apply !bg-transparent;
}

.MuiPaper-elevation1 {
	@apply !shadow;
}

.MuiPaper-rounded {
	@apply !rounded-none;
}

.gutter-horizontal:hover {
	cursor: col-resize;
}

.sound {
	box-sizing: content-box;
	position: absolute;
	height: 25%;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	transition: padding 0.0666667s linear 0s;
	border-radius: 50%;
	@apply bg-gray-100 square;
}

.ripple {
	box-sizing: content-box;
	position: absolute;
	height: 25%;
	top: 0%;
	left: 0%;
	transform: translateX(-50%) translateY(-50%);
	transition: padding 0.0666667s linear 0s;
	border-radius: 50%;
	@apply bg-red-500 square animate-ping;
}

body {
	--dyte-colors-brand-300: 0 255 225;
	--dyte-colors-brand-400: 0 255 255;
	--dyte-colors-brand-500: 255 102 0;
	--dyte-colors-brand-600: 0 123 116;
	--dyte-colors-brand-700: 107 114 128;
	--dyte-colors-background-600: 192 192 193;
	--dyte-colors-background-700: 243 244 246;
	--dyte-colors-background-800: 218 218 221;
	--dyte-colors-background-900: 230 230 230;
	--dyte-colors-background-1000: 255 255 255;
	--dyte-colors-text-1000: 255 255 255 / 1;
	--dyte-colors-text-900: 7 20 40 / 0.88;
	--dyte-colors-text-800: 7 20 40 / 0.76;
	--dyte-colors-text-700: 7 20 40 / 0.64;
	--dyte-colors-text-600: 7 20 40 / 0.52;
	--dyte-colors-video-bg: 229 231 235;
	--dyte-button-color: #000;
	--dyte-button-background-color: #dadadd;
	--vm-player-theme: #ff6600;
}

.meetingControls,
.setupScreen,
.settingModal,
.leaveModal {
	--dyte-colors-text-1000: 0 0 0 / 1;
	--dyte-button-background-color: #cdcdd0;
}
